"use client"

import { useEffect } from "react"

import { Button } from "@/components/ui/button"
import { CustomLink } from "@/components/ui/link"

export default function Error({
  error,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <main className="mx-auto max-w-xl flex-auto text-center">
      <h1 className="my-8 text-4xl font-black">Ups, coś poszło nie tak!</h1>
      <Button variant="link" asChild>
        <CustomLink
          href="/"
          rel={"noreferrer noopener"}
          ariaLabel={"Przejdź do strony głównej"}
          title={"Strona Główna"}
          target={"_blank"}
        >
          Spróbuj ponownie
        </CustomLink>
      </Button>
    </main>
  )
}
