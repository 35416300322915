import React, { type MouseEventHandler, type ReactNode } from "react"
import Link from "next/link"
import { cn } from "@/utils/cn"

interface Props {
  href: string
  rel: string
  ariaLabel: string
  title: string
  target: "_blank" | "_self" | "_parent" | "_top"
  text?: string
  children?: ReactNode
  className?: string
  prefetch?: boolean
  ariaDisabled?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
}
export const CustomLink = ({
  href,
  ariaLabel,
  rel = "noreferrer noopener",
  target = "_self",
  title,
  className,
  text,
  children,
  prefetch = true,
  ariaDisabled,
  onClick,
}: Props) => {
  return (
    <Link
      href={href}
      aria-label={ariaLabel}
      rel={rel}
      title={title}
      target={target}
      prefetch={prefetch}
      className={cn(
        "focus-visible:ring-ring rounded-md focus-visible:outline-none focus-visible:ring-1",
        className
      )}
      aria-disabled={ariaDisabled}
      onClick={onClick}
    >
      {text}
      {children}
    </Link>
  )
}
